import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"

import FlexSection from "../reusable-components/layout/flex-section"
import ChildCategoryCard from "../products/child-category-card"

import { getProperty } from "../../lib/utils"

const CategoriesRow = ({ categoriesRowData }) => {
  const rowQueryData = useStaticQuery(CATEGORIES_ROW_QUERY)

  // Since there is no way to get a sub-category's parents,
  // map through all paths and check if a path ends with the category name
  // then use that path
  categoriesRowData.forEach((cat) => {
    rowQueryData.allSitePage.nodes.forEach((item) => {
      if (item.path.endsWith(`/${cat.categoryTaxonomy.slug}/`)) {
        cat.categoryTaxonomy.slug = item.path
      }
    })
  })

  return (
    <CategoriesRowContainer>
      <FlexSectionStyling direction="row" wrap="wrap" justify="space-between">
        {categoriesRowData.map((category, categoryIndex) => {
          const categoryTaxonomy = getProperty(
            category,
            ["categoryTaxonomy"],
            ""
          )
          return (
            <ChildCategoryCard
              key={categoryIndex}
              childCategory={categoryTaxonomy}
              categoryPathName={""}
            />
          )
        })}
      </FlexSectionStyling>
    </CategoriesRowContainer>
  )
}

export default CategoriesRow

// ===============
//     STYLES
// ===============
const CategoriesRowContainer = styled.div`
  margin-top: 3.125rem;
`
const FlexSectionStyling = styled(FlexSection)`
  > a {
    width: 295px;
    margin: 10px 0;
  }
  > a:nth-child(3),
  > a:nth-child(4) {
    width: 610px;
    @media (max-width: ${({ theme }) => theme.breakLarge}) {
      width: 295px;
    }
    @media (max-width: ${({ theme }) => theme.breakMedium}) {
      width: 600px;
    }
    @media (max-width: ${({ theme }) => theme.breakSmall}) {
      width: 295px;
    }
  }
  img {
    margin-bottom: 0;
  }
  .cloudflare-image-wrapper {
    height: 350px;
  }
  > a > div {
    width: 295px;
    height: 80px;
  }
  > a:nth-child(3) > div,
  > a:nth-child(4) > div {
    width: 610px;
    @media (max-width: ${({ theme }) => theme.breakLarge}) {
      width: 295px;
    }
    @media (max-width: ${({ theme }) => theme.breakMedium}) {
      width: 600px;
    }
    @media (max-width: ${({ theme }) => theme.breakSmall}) {
      width: 295px;
    }
  }
  h4 {
    font-size: 1.75rem;
    line-height: 1;
  }
  a > div:not(:first-child) {
    margin-top: -80px;
  }
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    flex-direction: row;
    justify-content: center;
    > a {
      margin: 5px;
    }
  }
`

// ===============
//      QUERY
// ===============
const CATEGORIES_ROW_QUERY = graphql`
  {
    allSitePage(filter: { path: { regex: "/^((?!category).)*$/" } }) {
      nodes {
        path
      }
    }
  }
`
