import React from "react"
import styled from "styled-components"

import FlexSection from "../reusable-components/layout/flex-section"
import LinkWrapper from "../reusable-components/link-wrapper"

import { getProperty } from "../../lib/utils"
import CloudflareImage from "../reusable-components/cloudflare-image"

const PromotionalCards = ({ childCategoriesName, childCategoriesImage }) => {
  return (
    <>
      {!childCategoriesImage ? null : (
        <StyledImg
          alt="Promotional row image"
          className={"full-width-inner-section"}
          objectFit="cover"
          src={childCategoriesImage}
        />
      )}
      <ChildCategoryDataStyling className="child-category-data">
        <StyledH4>{childCategoriesName}</StyledH4>
      </ChildCategoryDataStyling>
    </>
  )
}

const PromotionalRow = ({ promotionalQueryData }) => {
  return (
    <PromotionalRowContainer>
      <FlexSectionStyling direction="row" wrap="wrap" justify="space-between">
        {promotionalQueryData?.map?.((childCategory, childIndex) => {
          const link = getProperty(
            childCategory,
            ["promotionTypeLink", "url"],
            []
          )
          const childCategoriesName = getProperty(
            childCategory,
            ["promotionTypeHeading"],
            []
          )
          const childCategoriesImage = getProperty(
            childCategory,
            ["promotionTypeImage", "sourceUrl"],
            false
          )
          return link.startsWith("/") ? (
            <LinkWrapper to={link} key={childIndex}>
              <PromotionalCards
                key={childIndex}
                childCategoriesName={childCategoriesName}
                childCategoriesImage={childCategoriesImage}
              />
            </LinkWrapper>
          ) : (
            <a href={link} target="_blank" rel="noreferrer" key={childIndex}>
              <PromotionalCards
                key={childIndex}
                childCategoriesName={childCategoriesName}
                childCategoriesImage={childCategoriesImage}
              />
            </a>
          )
        })}
      </FlexSectionStyling>
    </PromotionalRowContainer>
  )
}

export default PromotionalRow

// ===============
//     STYLES
// ===============
const PromotionalRowContainer = styled.div`
  margin-top: 3.125rem;
`
const FlexSectionStyling = styled(FlexSection)`
  > a {
    width: 295px;
  }
  > a:nth-child(2) {
    width: 610px;
    @media (max-width: ${({ theme }) => theme.breakLarge}) {
      width: 295px;
    }
  }
  .cloudflare-image-wrapper {
    height: 380px;
  }
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    flex-direction: row;
    justify-content: center;
    > a {
      margin: 10px 0;
    }
  }
`

const ChildCategoryDataStyling = styled.div`
  :not(:first-child) {
    margin-top: -80px;
  }
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white}bb;
`
const StyledH4 = styled.h4`
  font-size: 1.75rem;
  color: ${({ theme }) => theme.colors.black2};
  text-align: center;
`
const StyledImg = styled(CloudflareImage)`
  z-index: -1;
  & div {
    padding-bottom: 100% !important;
  }
`
