import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { ReactSVG } from "react-svg"

import { StyledCarousel } from "../reusable-components/layout/styled-carousel"
import CarouselArrow from "../reusable-components/layout/carousel-arrow"
import LinkWrapper from "../reusable-components/link-wrapper"

import { getProperty } from "../../lib/utils"

const BrandsRow = ({ brandsRowTitleData, brandsRowLogoData }) => {
  const title = getProperty(brandsRowTitleData, ["brandsRowTitle"], "")
  const subtitle = getProperty(brandsRowTitleData, ["brandsRowSubtitle"], "")
  const [showBrands, setShowBrands] = useState([])
  useEffect(() => {
    const existingBrands = []
    brandsRowLogoData?.forEach?.((brand) => {
      const brandLogo = getProperty(
        brand,
        [
          "acf_product_categories_and_brands",
          "brandLogo",
          "localFile",
          "publicURL",
        ],
        ""
      )
      if (brandLogo) {
        const brandUrl = getProperty(brand, ["slug"], "")
        existingBrands.push({
          brandLogo: brandLogo,
          brandUrl: brandUrl,
        })
      }
    })
    setShowBrands(existingBrands)
  }, [brandsRowLogoData])

  return (
    <BrandsRowContainer>
      <h2 dangerouslySetInnerHTML={{ __html: title }} />
      <p dangerouslySetInnerHTML={{ __html: subtitle }} />
      <StyledCarousel
        disableArrowsOnEnd={false}
        pagination={false}
        renderArrow={CarouselArrow}
        breakPoints={breakPoints}
      >
        {showBrands.map((brand, brand_i) => {
          return (
            <LinkWrapper
              key={brand_i}
              to={`/${process.env.GATSBY_BRANDS_PARENT_SLUG}/${brand.brandUrl}/`}
            >
              <StyledReactSVG src={brand.brandLogo} />
            </LinkWrapper>
          )
        })}
      </StyledCarousel>
    </BrandsRowContainer>
  )
}

export default BrandsRow

// ===============
//     STYLES
// ===============
const BrandsRowContainer = styled.div`
  text-align: center;
  > p {
    margin: 0 0 3.125rem;
  }
  margin: 6.25rem auto 3.125rem;
  .rec-carousel,
  .rec-carousel-wrapper {
    position: relative;
  }
  .rec-arrow {
    position: absolute;
    z-index: 5;
    background-color: ${({ theme }) => theme.colors.white}cc;
  }
  .rec-arrow-left {
    left: -100px;
    @media (max-width: calc(${({ theme }) => theme.breakLarge} + 250px)) {
      left: -50px;
    }
    @media (max-width: calc(${({ theme }) => theme.breakLarge} + 150px)) {
      left: 0;
    }
    @media (max-width: ${({ theme }) => theme.breakTiny}) {
      left: -25px;
    }
  }
  .rec-arrow-right {
    right: -100px;
    @media (max-width: calc(${({ theme }) => theme.breakLarge} + 250px)) {
      right: -50px;
    }
    @media (max-width: calc(${({ theme }) => theme.breakLarge} + 150px)) {
      right: 0;
    }
    @media (max-width: ${({ theme }) => theme.breakTiny}) {
      right: -25px;
    }
  }
`
const StyledReactSVG = styled(ReactSVG)`
  height: 60px;
  & > div {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  & svg {
    margin: 0 auto;
    width: 100%;
    max-width: 150px;
  }
`
// ===============
//    CONSTANTS
// ===============
const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 540, itemsToShow: 2 },
  { width: 900, itemsToShow: 4 },
  { width: 1220, itemsToShow: 6 },
]
