import React from "react"
import styled from "styled-components"

import ProductCarousel from "../products/product-carousel"
import Button from "../reusable-components/buttons/link-button"

import { getProperty } from "../../lib/utils"

const TrendingRow = ({ trendingRowData, trendingProductsData }) => {
  const title = getProperty(trendingRowData, ["trendingRowTitle"], "")
  const subtitle = getProperty(trendingRowData, ["trendingRowSubtitle"], "")
  const buttonText = getProperty(trendingRowData, ["trendingButtonText"], "")
  const buttonURL = getProperty(
    trendingRowData,
    ["trendingButtonUrl", "slug"],
    ""
  )
  const trendingProducts = []
  trendingProductsData?.forEach?.((category) => {
    const catProducts = getProperty(category, ["products", "nodes"], "")
    catProducts.forEach((product) => {
      if (product?.name) trendingProducts.push(product)
    })
  })
  const uniqueTrendingProducts = trendingProducts.filter(
    (product, productIndex, productsArray) =>
      productsArray.findIndex(
        (compareProduct) => compareProduct.productId === product.productId
      ) === productIndex
  )

  return (
    <TrendingRowContainer>
      <h2 dangerouslySetInnerHTML={{ __html: title }} />
      <p dangerouslySetInnerHTML={{ __html: subtitle }} />
      {uniqueTrendingProducts.length < 1 ? null : (
        <ProductCarousel
          products={uniqueTrendingProducts}
          listId="trending_products"
        />
      )}
      <StyledButton text={buttonText} to={`/${buttonURL}/`} />
    </TrendingRowContainer>
  )
}

export default TrendingRow

// ===============
//     STYLES
// ===============
const TrendingRowContainer = styled.div`
  text-align: center;
  > p {
    margin: 0 0 3.125rem;
  }
  margin: 6.25rem auto 3.125rem;
  .rec-arrow {
    z-index: 5;
    background-color: ${({ theme }) => theme.colors.white}cc;
  }
  .rec-arrow-left {
    left: -100px;
    @media (max-width: calc(${({ theme }) => theme.breakLarge} + 250px)) {
      left: -50px;
    }
    @media (max-width: calc(${({ theme }) => theme.breakLarge} + 150px)) {
      left: 0;
    }
    @media (max-width: ${({ theme }) => theme.breakLarge}) {
      left: -5px;
    }
    @media (max-width: ${({ theme }) => theme.breakTiny}) {
      left: -25px;
    }
  }
  .rec-arrow-right {
    right: -100px;
    @media (max-width: calc(${({ theme }) => theme.breakLarge} + 250px)) {
      right: -50px;
    }
    @media (max-width: calc(${({ theme }) => theme.breakLarge} + 150px)) {
      right: 0;
    }
    @media (max-width: ${({ theme }) => theme.breakLarge}) {
      right: -5px;
    }
    @media (max-width: ${({ theme }) => theme.breakTiny}) {
      right: -25px;
    }
  }
`
const StyledButton = styled(Button)`
  width: fit-content;
  margin: 1.875rem auto 3.125rem !important;
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    width: fit-content !important;
  }
`
