import React from "react"
import styled from "styled-components"

import FlexSection from "../reusable-components/layout/flex-section"
import RelatedArticleCard from "../blog-components/related-article-card"

import { getProperty } from "../../lib/utils"

const BlogsRow = ({ blogsRowTitleData, blogs }) => {
  const title = getProperty(blogsRowTitleData, ["blogRowTitle"], "")
  const subtitle = getProperty(blogsRowTitleData, ["blogRowSubtitle"], "")

  return (
    <BlogsRowContainer>
      <h2 dangerouslySetInnerHTML={{ __html: title }} />
      <p dangerouslySetInnerHTML={{ __html: subtitle }} />
      <FlexSectionStyling direction="row" wrap="wrap" justify="space-evenly">
        {blogs.map((blog, blog_i) => {
          const blogFeaturedImage = getProperty(
            blog,
            ["featuredImage", "node", "sourceUrl"],
            ""
          )

          return !blog ? null : (
            <RelatedArticleCard
              key={blog_i}
              post={blog}
              featuredImage={blogFeaturedImage}
            />
          )
        })}
      </FlexSectionStyling>
    </BlogsRowContainer>
  )
}

export default BlogsRow

// ===============
//     STYLES
// ===============
const BlogsRowContainer = styled.div`
  text-align: center;
  > p {
    margin-top: 0;
  }
  margin: 6.25rem auto 3.125rem;
`
const FlexSectionStyling = styled(FlexSection)`
  margin-top: 3.125rem;
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    flex-direction: row;
  }
  > div {
    width: 400px;
    margin: 1rem 0;
    @media (max-width: ${({ theme }) => theme.breakTiny}) {
      width: 100vw;
    }
  }
  h5 {
    text-align: left;
  }
`
