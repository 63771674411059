import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../components/theme/layout"
import SEO from "../components/theme/header/seo"
import LazyLoadWrapper from "../components/reusable-components/lazy-load-wrapper"

import DealsRow from "../components/home-page/deals-row"
import TrendingRow from "../components/home-page/trending-row"
import CategoriesRow from "../components/home-page/categories-row"
import BrandsRow from "../components/home-page/brands-row"
import ProjectsRow from "../components/home-page/projects-row"
import ReviewsRow from "../components/home-page/reviews-row"
import StatsRow from "../components/home-page/stats-row"
import BlogsRow from "../components/home-page/blogs-row"

import { getProperty } from "../lib/utils"
import PromotionalRow from "../components/home-page/promotional-row"

const IndexPage = ({ location }) => {
  const homePageQueryData = useStaticQuery(HOME_PAGE_QUERY)
  const title = getProperty(homePageQueryData, ["wpPage", "title"], "")
  const dealsQueryData = getProperty(
    homePageQueryData,
    ["wpPage", "acf_home_page", "dealsRow"],
    ""
  )
  const promotionalQueryData = getProperty(
    homePageQueryData,
    ["wpPage", "acf_home_page", "promotionalRow"],
    ""
  )

  const projectsQueryData = getProperty(
    homePageQueryData,
    ["wpPage", "acf_home_page", "projectsRow"],
    ""
  )
  const trendingRowQueryData = getProperty(
    homePageQueryData,
    ["wpPage", "acf_home_page", "trendingRow"],
    ""
  )
  const trendingProductsData = getProperty(
    homePageQueryData,
    ["trending", "wpChildren", "nodes"],
    ""
  )
  const categoriesRowData = getProperty(
    homePageQueryData,
    ["wpPage", "acf_home_page", "categoriesRow", "categoryRepeater"],
    ""
  )
  const brandsRowTitleData = getProperty(
    homePageQueryData,
    ["wpPage", "acf_home_page", "brandsRow"],
    ""
  )
  const brandsRowLogoData = getProperty(
    homePageQueryData,
    ["brands", "wpChildren", "nodes"],
    ""
  )
  const reviewsQueryData = getProperty(
    homePageQueryData,
    ["wpPage", "acf_home_page", "reviewsRow"],
    ""
  )
  const statsQueryData = getProperty(
    homePageQueryData,
    ["wpPage", "acf_home_page", "statsRow"],
    ""
  )
  const blogsRowQueryData = getProperty(
    homePageQueryData,
    ["wpPage", "acf_home_page", "blogsRow"],
    ""
  )
  const blogsRowPosts = getProperty(
    homePageQueryData,
    ["allWpPost", "nodes"],
    ""
  )

  return (
    <Layout location={location}>
      <SEO
        title={getProperty(homePageQueryData, ["seaPage", "seo", "title"], "")}
        location={location}
        yoast={getProperty(homePageQueryData, ["seaPage", "seo"], {})}
      />
      <DealsRow deals={dealsQueryData} />
      <PromotionalRow promotionalQueryData={promotionalQueryData} />
      <LazyLoadWrapper
        lazyComponent={
          <TrendingRow
            trendingRowData={trendingRowQueryData}
            trendingProductsData={trendingProductsData}
          />
        }
      />
      <LazyLoadWrapper
        lazyComponent={<CategoriesRow categoriesRowData={categoriesRowData} />}
      />
      <LazyLoadWrapper
        lazyComponent={
          <BrandsRow
            brandsRowTitleData={brandsRowTitleData}
            brandsRowLogoData={brandsRowLogoData}
          />
        }
      />
      <LazyLoadWrapper
        lazyComponent={
          <ProjectsRow
            projectsData={projectsQueryData}
            className={"page-fullwidth-section"}
          />
        }
      />
      <LazyLoadWrapper
        lazyComponent={<ReviewsRow reviewsData={reviewsQueryData} />}
      />
      <LazyLoadWrapper
        lazyComponent={<StatsRow statsRowData={statsQueryData} />}
      />
      <LazyLoadWrapper
        lazyComponent={
          <BlogsRow
            blogsRowTitleData={blogsRowQueryData}
            blogs={blogsRowPosts}
          />
        }
      />
    </Layout>
  )
}

export default IndexPage

// ===============
//      QUERY
// ===============
const HOME_PAGE_QUERY = graphql`
  {
    wpPage(slug: { eq: "home" }) {
      title
      content
      acf_home_page {
        dealsRow {
          dealIntroTitle
          dealMainTitle
          dealDescription
          dealFeatureImage {
            sourceUrl
          }
          dealButtonText
          dealButtonUrl {
            url
          }
        }
        promotionalRow {
          promotionTypeHeading
          promotionTypeImage {
            sourceUrl
          }
          promotionTypeLink {
            url
          }
        }
        trendingRow {
          trendingRowTitle
          trendingRowSubtitle
          trendingButtonText
          trendingButtonUrl {
            ... on WpPage {
              slug
            }
          }
        }
        categoriesRow {
          categoryRepeater {
            categoryTaxonomy {
              name
              slug
              count
              image {
                sourceUrl
              }
            }
          }
        }
        brandsRow {
          brandsRowTitle
          brandsRowSubtitle
        }
        projectsRow {
          projectsTitle
          projectsSubtitle
          projectsDescriptionHeading
        }
        reviewsRow {
          reviewsTitle
          reviewsSubtitle
          reviewsRepeater {
            reviewerName
            reviewerExtract
            reviewerComment
            reviewerImage {
              sourceUrl
            }
          }
        }
        statsRow {
          statsTitle
          statsSubtitle
          statsRepeater {
            statText
            statNumber
          }
        }
        blogsRow {
          blogRowTitle
          blogRowSubtitle
        }
      }
    }
    allWpPost(sort: { order: DESC, fields: dateGmt }, limit: 3) {
      nodes {
        title
        slug
        date(formatString: "DD MMMM yyyy")
        dateGmt(formatString: "x")
        featuredImage {
          node {
            sourceUrl
          }
        }
      }
    }
    trending: wpProductCategory(slug: { eq: "promotional" }) {
      wpChildren {
        nodes {
          name
          products {
            nodes {
              ... on WpSimpleProduct {
                name
                productId: databaseId
                shortDescription
                price
                regularPrice
                onSale
                reviews {
                  averageRating
                }
                sku
                slug
                categories: productCategories {
                  nodes {
                    slug
                    databaseId
                  }
                }
                image {
                  sourceUrl
                }
                acf_product_information {
                  productHoverImage {
                    sourceUrl
                  }
                }
                acf_product_tile_add_ons {
                  squareMetresOfTilesPerBox
                }
              }
            }
          }
        }
      }
    }
    brands: wpProductCategory(slug: { eq: "brands" }) {
      wpChildren {
        nodes {
          name
          slug
          acf_product_categories_and_brands {
            brandLogo {
              localFile {
                publicURL
              }
            }
          }
        }
      }
    }
    seaPage(slug: { eq: "home" }) {
      seo {
        description
        og_description
        og_image
        og_title
        og_type
        site_name
        title
        twitter_card
        twitter_creator
        twitter_description
        twitter_title
      }
    }
  }
`
