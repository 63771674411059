import React, { useRef, useState, useEffect } from "react"
import styled from "styled-components"

import FlexSection from "../reusable-components/layout/flex-section"
import Button from "../reusable-components/buttons/link-button"
import { StyledCarousel } from "../reusable-components/layout/styled-carousel"
import { ControlledCarouselArrow } from "../reusable-components/layout/carousel-arrow"

import { getProperty } from "../../lib/utils"
import CloudflareImage from "../reusable-components/cloudflare-image"

// TODO these global varialbes should be useRefs because they are modified in the component
let autoplayTimeout
let autoplayDelayTimeout
let clickTimeout

const autoplaySpeed = 10000
const exitDelay = 800

const DealsRow = ({ deals }) => {
  const carouselRef = useRef(null)
  const [currentItemIndex, setCurrentItemIndex] = useState(0)
  const [exitAnimationIndex, setExitAnimationIndex] = useState(-1)

  // clear all timeouts when this component unmounts
  useEffect(() => {
    return () => {
      clearTimeout(autoplayTimeout)
      clearTimeout(autoplayDelayTimeout)
      clearTimeout(clickTimeout)
    }
  }, [])

  useEffect(() => {
    // clear the timeout to avoid going to 0 more than once
    clearTimeout(autoplayTimeout)
    // if we're on the last slide
    autoplayTimeout = setTimeout(() => {
      // go to the first slide
      setExitAnimationIndex(currentItemIndex)

      // clear timeout before scheduling the next
      clearTimeout(autoplayDelayTimeout)
      autoplayDelayTimeout = setTimeout(() => {
        if (currentItemIndex + 1 >= deals.length) {
          carouselRef?.current?.goTo(0)
          setCurrentItemIndex(0)
        } else {
          carouselRef?.current?.goTo(currentItemIndex + 1)
          setCurrentItemIndex(currentItemIndex + 1)
        }
      }, exitDelay)
    }, autoplaySpeed) // same time as autoPlaySpeed
  }, [currentItemIndex])

  const clickHandler = (props) => {
    setExitAnimationIndex(currentItemIndex)

    // clear timeout before scheduling the next
    clearTimeout(clickTimeout)
    clickTimeout = setTimeout(() => {
      if (props === "rec-arrow-right") {
        if (currentItemIndex === deals.length - 1) {
          // we hit the last item, go to first item
          carouselRef?.current?.goTo(0)
          setCurrentItemIndex(0)
        } else {
          carouselRef?.current?.goTo(currentItemIndex + 1)
          setCurrentItemIndex(currentItemIndex + 1)
        }
      } else if (props === "rec-arrow-left") {
        if (currentItemIndex === 0) {
          // we hit the first item, go to last item
          carouselRef?.current?.goTo(deals.length)
          setCurrentItemIndex(deals.length - 1)
        } else {
          carouselRef?.current?.goTo(currentItemIndex - 1)
          setCurrentItemIndex(currentItemIndex - 1)
        }
      }
    }, exitDelay)
  }

  return (
    <DealsRowContainer id="deals-carousel">
      <CustomCarouselStyling
        ref={carouselRef}
        transitionMs={0}
        enableTilt={false}
        renderArrow={({ onClick, ...props }) => (
          <ControlledCarouselArrow
            {...props}
            delay={1000}
            carouselId={"deals-carousel"}
            onClick={clickHandler}
          />
        )}
      >
        {deals.map((deal, deal_i) => {
          const introTitle = getProperty(deal, ["dealIntroTitle"], "")
          const mainTitle = getProperty(deal, ["dealMainTitle"], "")
          const description = getProperty(deal, ["dealDescription"], "")
          const buttonText = getProperty(deal, ["dealButtonText"], "")
          const buttonURL = getProperty(deal, ["dealButtonUrl", "url"], "")

          return !mainTitle ? null : (
            <FlexSectionStyling key={deal_i} direction="row" margin="0 0 50px">
              <InfoSectionStyling
                className={`inactive ${
                  deal_i == currentItemIndex ? "active" : "inactive"
                } ${deal_i == exitAnimationIndex ? "exit-animation" : ""}`}
              >
                <h6 dangerouslySetInnerHTML={{ __html: introTitle }} />
                <h2 dangerouslySetInnerHTML={{ __html: mainTitle }} />
                <div dangerouslySetInnerHTML={{ __html: description }} />
                {buttonURL?.startsWith?.("/") ? (
                  <StyledButton text={buttonText} to={buttonURL} />
                ) : (
                  <StyledExternalButton>
                    <a href={buttonURL} target="_blank" rel="noreferrer">
                      {buttonText}
                    </a>
                  </StyledExternalButton>
                )}
              </InfoSectionStyling>
              <StyledImg
                src={getProperty(deal, ["dealFeatureImage", "sourceUrl"], "")}
              ></StyledImg>
            </FlexSectionStyling>
          )
        })}
      </CustomCarouselStyling>
    </DealsRowContainer>
  )
}

export default DealsRow

// ===============
//     STYLES
// ===============
const DealsRowContainer = styled.div`
  min-height: 385px;
  > div {
    flex-direction: row-reverse;
  }
  @media (max-width: calc(${({ theme }) => theme.breakLarge} + 240px)) {
    flex-direction: column;
  }
  > div:first-child {
    @media (max-width: calc(${({ theme }) => theme.breakLarge} + 120px)) {
      padding: 0 40px;
    }
    @media (max-width: ${({ theme }) => theme.breakLarge}) {
      padding: 0;
    }
  }
`
const CustomCarouselStyling = styled(StyledCarousel)`
  margin: 3.125rem auto 1.875rem;
  position: relative;
  min-height: 385px;
  max-width: ${({ theme }) => theme.breakLarge};
  .rec-pagination {
    flex-direction: column;
    position: absolute;
    left: calc(0vw - 50vw + 620px + 50px);
    @media (max-width: calc(${({ theme }) => theme.breakLarge} + 240px)) {
      left: calc(0vw - 50vw + 620px + 20px);
    }
    @media (max-width: calc(${({ theme }) => theme.breakLarge} + 40px)) {
      left: calc(0vw - 50vw + 620px + 20px);
    }
    @media (max-width: ${({ theme }) => theme.breakLarge}) {
      left: calc(0vw - 50vw + 480px + 20px);
    }
    @media (max-width: ${({ theme }) => theme.breakMedium}) {
      flex-direction: row;
      flex-wrap: nowrap;
      top: auto;
      bottom: 25px;
      left: 5px;
    }
  }
  .rec-arrow {
    position: absolute;
    top: 21.5rem;
    margin: auto 0 0;
    @media (max-width: ${({ theme }) => theme.breakLarge}) {
      top: auto;
      bottom: 10px;
    }
    @media (max-width: ${({ theme }) => theme.breakSmall}) {
      margin: auto 0;
    }
  }

  .rec-arrow-left {
    right: calc(0vw - 50vw + 620px + 140px);
    order: 2;
    @media (max-width: calc(${({ theme }) => theme.breakLarge} + 240px)) {
      right: calc(0vw - 50vw + 580px + 140px);
    }
    @media (max-width: calc(${({ theme }) => theme.breakLarge})) {
      right: calc(0vw - 50vw + 440px + 140px);
    }
    @media (max-width: calc(${({ theme }) => theme.breakMedium})) {
      right: 75px;
    }
    @media (max-width: ${({ theme }) => theme.breakSmall}) {
      order: 1;
    }
  }
  .rec-arrow-right {
    right: calc(0vw - 50vw + 620px + 70px);
    order: 3;
    @media (max-width: calc(${({ theme }) => theme.breakLarge} + 240px)) {
      right: calc(0vw - 50vw + 580px + 70px);
    }
    @media (max-width: calc(${({ theme }) => theme.breakLarge})) {
      right: calc(0vw - 50vw + 440px + 70px);
    }
    @media (max-width: calc(${({ theme }) => theme.breakMedium})) {
      right: 5px;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    margin-top: 0;
  }
`
const FlexSectionStyling = styled(FlexSection)`
  @media (max-width: ${({ theme }) => theme.breakLarge}) {
    flex-direction: column-reverse;
    margin-bottom: 0;
  }
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    margin-bottom: 50px;
  }
`
const InfoSectionStyling = styled.div`
  width: 620px;
  height: 335px;
  margin-right: 20px;
  h6 {
    font-size: 1rem;
    color: ${({ theme }) => theme.colors.grey};
  }
  > div {
    margin: 40px 0;
    font-size: 1.313rem;
    line-height: 1.667;
    max-height: 67px;
    overflow: hidden;
    text-transform: uppercase;
    @media (max-width: ${({ theme }) => theme.breakLarge}) {
      max-height: none;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakLarge}) {
    width: 100%;
    height: auto;
    margin-bottom: 2rem;
  }
  &.active > :nth-child(1) {
    animation: slideIn1 0.8s ease;
  }
  &.active > :nth-child(2) {
    animation: slideIn2 1.6s ease;
  }
  &.active > :nth-child(3) {
    animation: slideIn3 2.4s ease;
  }
  &.active > :nth-child(4) {
    animation: slideIn4 2.8s ease;
  }
  @keyframes slideIn1 {
    0% {
      transform: translateX(-610px);
    }
    100% {
      transform: translateX(0);
    }
  }

  @keyframes slideIn2 {
    0% {
      transform: translateX(-1220px);
    }
    100% {
      transform: translateX(0);
    }
  }

  @keyframes slideIn3 {
    0% {
      transform: translateX(-1830px);
    }
    100% {
      transform: translateX(0);
    }
  }

  @keyframes slideIn4 {
    0% {
      transform: translateX(-2000px);
    }
    100% {
      transform: translateX(0);
    }
  }
  &.exit-animation&.active > :nth-child(1) {
    animation: slideOut 0.8s ease;
  }
  &.exit-animation&.active > :nth-child(2) {
    animation: slideOut 0.6s ease 0.2s;
  }
  &.exit-animation&.active > :nth-child(3) {
    animation: slideOut 0.4s ease 0.4s;
  }
  &.exit-animation&.active > :nth-child(4) {
    animation: slideOut 0.2s ease 0.6s;
  }
  @keyframes slideOut {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100vw);
    }
  }
`
const StyledButton = styled(Button)`
  width: fit-content;
  @media (max-width: ${({ theme }) => theme.breakLarge}) {
    width: fit-content;
  }
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    width: fit-content !important;
  }
`
const StyledExternalButton = styled.button`
  @media (max-width: ${({ theme }) => theme.breakLarge}) {
    width: fit-content;
  }
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    width: fit-content !important;
  }
`
const StyledImg = styled(CloudflareImage)`
  width: 620px;
  height: 335px;
  @media (max-width: ${({ theme }) => theme.breakLarge}) {
    width: 100%;
    max-height: 40vw;
    height: auto;
    margin-bottom: 20px;
  }
  &.exit-animation&.active {
    animation: fadeOut ease 2s;
  }
  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  &.active {
    animation: fadeIn ease 3s;
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`
