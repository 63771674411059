import styled from "styled-components"
import Carousel from "react-elastic-carousel"

export const StyledCarousel = styled(Carousel)`
  margin: 3.125rem 0 1.875rem;
  .rec-slider-container {
    margin: 0;
  }
  .rec-dot {
    background-color: ${({ theme }) => theme.colors.black3};
    padding: 0;
    box-shadow: none;
    width: 6px;
    height: 6px;
  }
  .rec-dot_active {
    background-color: ${({ theme }) => theme.colors.blue};
    box-shadow: 0 0 0 2.5px white,
      0 0 0 3.5px ${({ theme }) => theme.colors.blue};
  }
  .rec-arrow {
    height: 50px;
    width: 50px;
    padding: 0;
    margin: auto 0;
    z-index: 5;
    color: ${({ theme }) => theme.colors.black1};
    background-color: white;
    box-shadow: none;
    border: none;
    border-radius: 50%;
    img {
      margin-bottom: 0;
    }
  }
  .rec-arrow:hover:enabled {
    line,
    path {
      stroke: white;
    }
    background-color: ${({ theme }) => theme.colors.grey};
    box-shadow: none;
  }
  .rec-arrow:focus:enabled {
    line,
    path {
      stroke: white;
    }
    background-color: ${({ theme }) => theme.colors.grey};
    box-shadow: none;
  }
  .rec-arrow-right {
    transform: scaleX(-1);
  }
`
