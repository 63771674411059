import React from "react"
import styled from "styled-components"

import FlexSection from "../reusable-components/layout/flex-section"

import { getProperty } from "../../lib/utils"

const StatsRow = ({ statsRowData }) => {
  const title = getProperty(statsRowData, ["statsTitle"], "")
  const subtitle = getProperty(statsRowData, ["statsSubtitle"], "")
  const stats = getProperty(statsRowData, ["statsRepeater"], "")

  return (
    <StatsRowContainer>
      <h2 dangerouslySetInnerHTML={{ __html: title }} />
      <p dangerouslySetInnerHTML={{ __html: subtitle }} />
      <FlexSectionStyling direction="row" wrap="wrap" justify="space-evenly">
        {stats.map((stat, stat_i) => {
          const number = getProperty(stat, ["statNumber"], "")
          const text = getProperty(stat, ["statText"], "")

          return !number ? null : (
            <StatSection key={stat_i}>
              <h2 dangerouslySetInnerHTML={{ __html: number }} />
              <h3 dangerouslySetInnerHTML={{ __html: text }} />
            </StatSection>
          )
        })}
      </FlexSectionStyling>
    </StatsRowContainer>
  )
}

export default StatsRow

// ===============
//     STYLES
// ===============
const StatsRowContainer = styled.div`
  text-align: center;
  > p {
    margin-top: 0;
  }
  margin: 6.25rem auto 3.125rem;
`
const FlexSectionStyling = styled(FlexSection)`
  margin-top: 3.125rem;
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    flex-direction: row;
  }
`
const StatSection = styled.div`
  width: 188px;
  height: 188px;
  padding: 1.5rem;
  background-color: ${({ theme }) => theme.colors.black};
  border-radius: 50%;
  h2 {
    color: white;
    font-size: 1.5rem;
    padding-top: 1rem;
  }
  h3 {
    color: white;
    font-size: 1.175rem;
    padding-top: 0.725rem;
  }
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    margin: 0.5rem;
  }
`
const NameSection = styled.div`
  padding-left: 3.125rem;
  margin: auto 0;
  word-break: break-word;
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    padding-left: 0;
    margin: auto;
  }
`
