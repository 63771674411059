import React from "react"
import styled from "styled-components"
import CloudflareImage from "../reusable-components/cloudflare-image"

import FlexSection from "../reusable-components/layout/flex-section"
import { StyledCarousel } from "../reusable-components/layout/styled-carousel"
import CarouselArrow from "../reusable-components/layout/carousel-arrow"

import { getProperty } from "../../lib/utils"

const ReviewsRow = ({ reviewsData }) => {
  const title = getProperty(reviewsData, ["reviewsTitle"], "")
  const subtitle = getProperty(reviewsData, ["reviewsSubtitle"], "")
  const reviews = getProperty(reviewsData, ["reviewsRepeater"], "")

  return (
    <ReviewsRowContainer>
      <h2 dangerouslySetInnerHTML={{ __html: title }} />
      <p dangerouslySetInnerHTML={{ __html: subtitle }} />
      <StyledCarousel renderArrow={CarouselArrow} pagination={false}>
        {reviews.map((review, review_i) => {
          const reviewerName = getProperty(review, ["reviewerName"], "")
          const reviewerExtract = getProperty(review, ["reviewerExtract"], "")
          const reviewerImage = getProperty(
            review,
            ["reviewerImage", "sourceUrl"],
            ""
          )
          const reviewerComment = getProperty(review, ["reviewerComment"], "")

          return !reviewerName ? null : (
            <FlexSectionStyling key={review_i} direction="row" wrap="wrap">
              <CloudflareImage src={reviewerImage} objectFit="cover" />
              <NameSection>
                <h4 dangerouslySetInnerHTML={{ __html: reviewerName }} />
                <h6 dangerouslySetInnerHTML={{ __html: reviewerExtract }} />
              </NameSection>
              <div dangerouslySetInnerHTML={{ __html: reviewerComment }} />
            </FlexSectionStyling>
          )
        })}
      </StyledCarousel>
    </ReviewsRowContainer>
  )
}

export default ReviewsRow

// ===============
//     STYLES
// ===============
const ReviewsRowContainer = styled.div`
  margin: 6.25rem auto 3.125rem;
  text-align: center;
  > p {
    margin-top: 0;
  }
  .rec-arrow {
    min-width: 50px;
    margin: auto 5px auto 0;
  }
`
const FlexSectionStyling = styled(FlexSection)`
  max-width: 820px;
  margin-bottom: 0;
  text-align: left;
  .cloudflare-image-wrapper {
    width: 110px;
    height: 110px;
    border-radius: 50%;
    margin-bottom: 0;
  }
  h6 {
    color: ${({ theme }) => theme.colors.grey};
    font-style: italic;
    font-size: 1.313rem;
  }
  > div:last-child {
    min-width: 100%;
    margin-top: 3.125rem;
  }
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    flex-direction: row;
    .cloudflare-image-wrapper {
      margin: auto;
    }
    > div:last-child {
      margin-top: 1.5rem;
    }
  }
`
const NameSection = styled.div`
  padding-left: 3.125rem;
  margin: auto 0;
  word-break: break-word;
  h4 {
    text-transform: none;
  }
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    padding-left: 0;
    margin: auto;
  }
`
