import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import BackgroundImage from "gatsby-background-image"

import FlexSection from "../reusable-components/layout/flex-section"
import Button from "../reusable-components/buttons/link-button"
import { StyledCarousel } from "../reusable-components/layout/styled-carousel"
import CarouselArrow from "../reusable-components/layout/carousel-arrow"

import { getProperty } from "../../lib/utils"
import CloudflareImage from "../reusable-components/cloudflare-image"

const ProjectsRow = ({ projectsData, className = "" }) => {
  const title = getProperty(projectsData, ["projectsTitle"], "")
  const subtitle = getProperty(projectsData, ["projectsSubtitle"], "")
  const descriptionHeading = getProperty(
    projectsData,
    ["projectsDescriptionHeading"],
    ""
  )
  const projectsQueryData = useStaticQuery(PROJECTS_PAGE_QUERY)
  const projects = getProperty(
    projectsQueryData,
    ["wpPage", "acf_completed_projects", "projects"],
    []
  )

  return (
    <ProjectsRowContainer className={className}>
      <h2 dangerouslySetInnerHTML={{ __html: title }} />
      <p dangerouslySetInnerHTML={{ __html: subtitle }} />
      <CustomCarouselStyling renderArrow={CarouselArrow}>
        {projects.map((project, project_i) => {
          const name = getProperty(project, ["projectTitle"], "")
          const category = getProperty(project, ["projectCategory"], "")
          const featuredImage = getProperty(
            project,
            ["projectFeaturedImage", "localFile", "childImageSharp", "fluid"],
            ""
          )
          const shortDescription = getProperty(
            project,
            ["projectShortDescription"],
            ""
          )
          const galleryImages = getProperty(project, ["projectGallery"], "")
          const galleryImage = getProperty(
            galleryImages[1],
            ["projectGalleryImage", "sourceUrl"],
            ""
          )

          return !name ? null : (
            <FlexSectionStyling key={project_i} direction="row" margin="0">
              <BackgroundImageStyling fluid={featuredImage}>
                <TextOverBackgroundStyling>
                  <h4 dangerouslySetInnerHTML={{ __html: name }} />
                  <h5 dangerouslySetInnerHTML={{ __html: category }} />
                </TextOverBackgroundStyling>
              </BackgroundImageStyling>
              <InfoBox>
                <h5>{descriptionHeading}</h5>
                <div dangerouslySetInnerHTML={{ __html: shortDescription }} />
              </InfoBox>
              <CloudflareImage src={galleryImage} objectFit="cover" />
            </FlexSectionStyling>
          )
        })}
      </CustomCarouselStyling>
      <StyledButton text="View all projects" to={`/completed-projects/`} />
    </ProjectsRowContainer>
  )
}

export default ProjectsRow

// ===============
//     STYLES
// ===============
const ProjectsRowContainer = styled.div`
  text-align: center;
  > p {
    margin-top: 0;
  }
  margin: 3.125rem auto;
`

const CustomCarouselStyling = styled(StyledCarousel)`
  .rec-carousel {
    position: relative;
  }
  .rec-arrow {
    position: absolute;
    top: 21rem;
    z-index: 1;
    margin: auto;
    @media (max-width: ${({ theme }) => theme.breakLarge}) {
      top: 46rem;
    }
    @media (max-width: ${({ theme }) => theme.breakSmall}) {
      top: 71rem;
    }
  }
  .rec-arrow-left {
    right: 5.375rem;
    @media (max-width: ${({ theme }) => theme.breakSmall}) {
      right: auto;
      left: 1.5rem;
    }
  }
  .rec-arrow-right {
    right: 1.5rem;
  }
`
const FlexSectionStyling = styled(FlexSection)`
  @media (max-width: ${({ theme }) => theme.breakLarge}) {
    flex-direction: row;
    flex-wrap: wrap;
  }
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    margin-bottom: 0;
  }
  .cloudflare-image-wrapper {
    width: 50vw;
    height: 400px;
    @media (max-width: ${({ theme }) => theme.breakLarge}) {
      width: 100vw;
    }
  }
`
const TextOverBackgroundStyling = styled.div`
  padding: 3.75rem 2.813rem;
  position: absolute;
  opacity: 1 !important;
  width: 100%;
  background: inherit;
  background-attachment: fixed;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 70%;
    height: 100%;
    background: inherit;
    background-attachment: fixed;
    -webkit-filter: blur(50px);
    filter: blur(50px);
  }
  ::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 70%;
    height: 100%;
    filter: blur(50px);
    background: ${({ theme }) => theme.colors.white}ee;
  }
`
const BackgroundImageStyling = styled(BackgroundImage)`
  width: 25vw;
  height: 400px;
  text-align: left;
  position: relative;
  opacity: 1 !important;
  h4 {
    font-size: 1.75rem;
    line-height: 1;
    z-index: 10;
    position: relative;
  }
  h5 {
    font-size: 1rem;
    font-weight: normal;
    text-transform: uppercase;
    z-index: 10;
    position: relative;
  }
  @media (max-width: ${({ theme }) => theme.breakLarge}) {
    width: 50%;
  }
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    width: 100%;
  }
`
const InfoBox = styled.div`
  width: 25vw;
  height: 400px;
  padding: 3.125rem;
  text-align: left;
  h5 {
    margin-bottom: 2.5rem;
  }
  div {
    max-height: 230px;
    overflow: hidden;
  }
  @media (max-width: ${({ theme }) => theme.breakLarge}) {
    width: 50%;
  }
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    width: 100%;
  }
`
const StyledButton = styled(Button)`
  width: fit-content;
  margin: 0 auto !important;
  @media (max-width: ${({ theme }) => theme.breakLarge}) {
    width: fit-content;
  }
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    width: fit-content !important;
  }
`

// ===============
//      QUERY
// ===============
const PROJECTS_PAGE_QUERY = graphql`
  {
    wpPage(slug: { eq: "completed-projects" }) {
      acf_completed_projects {
        projects {
          projectCategory
          projectTitle
          projectShortDescription
          projectFeaturedImage {
            localFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          projectGallery {
            projectGalleryImage {
              sourceUrl
            }
          }
        }
      }
    }
  }
`
